<template>
    <div>
      <b-button-group size="sm">
        <b-button variant="outline-primary" @click="removeItem(cellValue.data.id)">
          <feather-icon icon="Trash2Icon" class="mr-50" />
          <span class="align-middle">Eliminar</span>
        </b-button>
      </b-button-group>

  
    </div>
  </template>
  
  <script>
  import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  import useApiServices from '@/services/useApiServices.js';
  
  export default {
    components: {
      BRow,
      BCol,
      BButtonGroup,
      BButton,
      BModal,
      BCardText,
      BDropdown,
      BDropdownItem,
      AgGridTable,
      BrunaElementLoader
    },
  
    directives: {
    },
  
    data: function ()
    {
      return {
        cellValue: null,
  
  
  
  
  
      };
    },
    beforeMount()
    {
      // this.params contains the cell & row information and is made available to this component at creation time
      // see ICellRendererParams below for more details
      this.cellValue = this.getValueToDisplay(this.params);
  
  
  
  
    },
    methods: {
      // gets called whenever the user gets the cell to refresh
      refresh(params)
      {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
      },
  
      removeItem(item_id)
      {

        this.$bvModal.msgBoxConfirm('¿Quieres eliminar el contrato?', {          
            okTitle: 'Confirmar',
            cancelTitle: 'Cancelar'
        })
          .then(value => {

            if(value)
            {
                useApiServices.postContratoDelete({id: item_id})
                    .then((response) =>
                    {
                        this.params.api.refreshServerSideStore({ route: [], purge: false });


                    });

            }





          })
          .catch(err => {
            // An error occurred
          })





      },
  

  
      getValueToDisplay(params)
      {
        return params;
        //return params.valueFormatted ? params.valueFormatted : params.value;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@core/scss/vue/pages/ui-feather.scss";
  </style>
  