<template>
  <section>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table :configUrl="config" :dataUrl="data"></ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import
  {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormFile,
    BCardTitle,
    BCardText,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BTable,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    BTab,
    VBPopover,
    VBTooltip,
  } from "bootstrap-vue";
import Screenshot from "@/views/clients/fiordo/components/Screenshot";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import HistoricoDemandaActions from "./HistoricoDemandaActions.vue";

export default {
  name: "Ingreso Contratos",
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormFile,
    BCardTitle,
    BCardText,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BTable,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    BTab,
    Screenshot,
    VBPopover,
    VBTooltip,
    AgGridTable,
    RepeatStatistics,
    BrunaForm, 
    ToastificationContent,

    historicoDemandaActions: HistoricoDemandaActions
  },
  data()
  {
    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    return {
      tabIndex: tabIndex,
      config: useApiServices.ingresoContratosConfig,
      data: useApiServices.ingresoContratosData,
      itemsData: [],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  // Repeat Statistics Cards
  mounted()
  {
    axios
      .get(useApiServices.ingresoContratosStatistics)
      .then((response) =>
      {
        this.itemsData = response.data;
      })
      .catch((error) => { });
  },
  //Form methods
  methods: {

  },
};
</script>
<style lang="scss">
</style>